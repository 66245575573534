/* auth.css */

body {
    font-family: 'Arial', sans-serif;
    background-color: #1e1e2f;
    color: #fff;
  }
  
  .auth-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #2c2c3e;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .auth-title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #ff6f61;
  }
  
  .auth-form {
    display: flex;
    flex-direction: column;
  }
  
  .auth-input {
    margin-bottom: 15px;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ff6f61;
    border-radius: 5px;
    background-color: #3e3e50;
    color: #fff;
  }
  
  .auth-input:focus {
    outline: none;
    border-color: #ff6f61;
  }
  
  .auth-button, .auth-google-button {
    padding: 10px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .auth-button {
    background-color: #ff6f61;
    color: #fff;
    margin-bottom: 10px;
  }
  
  .auth-button:hover {
    background-color: #e65b56;
  }
  
  .auth-google-button {
    background-color: #4285f4;
    color: #fff;
  }
  
  .auth-google-button:hover {
    background-color: #357ae8;
  }
  