@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Damion&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Acme&family=Courgette&family=Damion&display=swap');

.nav-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(to right, #0A1128, #3B0D5C); /* Corrected the background gradient syntax */
  padding: 10px 20px;
  flex-wrap: wrap; /* Allows items to wrap when necessary */
}

.logo-container {
  display: flex;
  align-items: center;
}

.pname {
  color: aliceblue;
  font-family: "Acme", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
  letter-spacing: 1.5px;
}

.tagname {
  color: antiquewhite;
  font-family: "Damion", cursive;
  font-weight: 400;
  font-style: normal;
  font-size: 30px;
}

.logo {
  width: 70px; /* Adjust the size as necessary */
  height: auto;
}

.nav-links {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;
  font-family: "Acme", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.nav-links li {
  margin-left: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
}

.nav-links a:hover {
  text-decoration: underline;
}

/* Media queries for responsiveness */
@media (max-width: 768px) {
  .nav-container {
    flex-direction: column; /* Stack the nav on smaller screens */
    align-items: center;
    padding: 10px;
  }

  .logo-container {
    margin-bottom: 10px; /* Add space between the logo and links on small screens */
  }

  .pname {
    font-size: 24px; /* Reduce font size on smaller screens */
  }

  .tagname {
    font-size: 24px; /* Reduce font size on smaller screens */
  }

  .nav-links {
    flex-direction: column; /* Stack the links vertically */
    align-items: center;
  }

  .nav-links li {
    margin-left: 0;
    margin-bottom: 10px; /* Add space between the links */
  }

  .nav-links a {
    font-size: 16px; /* Adjust the link size for better readability */
  }
}

@media (max-width: 480px) {
  /* For mobile phones */
  .pname {
    font-size: 20px;
  }

  .tagname {
    font-size: 20px;
  }

  .logo {
    width: 50px; /* Scale down the logo for mobile */
  }

  .nav-links a {
    font-size: 14px; /* Adjust link size for mobile */
  }
}
