@import url('https://fonts.googleapis.com/css2?family=Acme&family=Courgette&family=Damion&display=swap');

.home-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack elements vertically for smaller screens */
  width: 100%;
  padding: 20px;
}

/* Ensure the image container is responsive */
.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  width: 100%;
}

.home-image {
  max-width: 100%; /* Make sure image scales down on smaller screens */
  height: auto;
  border-radius: 30px;
  opacity: 0.8;
}

.step-h1 {
  font-family: 'Acme', sans-serif; /* Use the same font family for consistency */
  font-size: 36px; /* Large heading size */
  color: #00d9ff; /* Use the same color scheme for consistency */
  text-align: center; /* Center the heading */
  margin-bottom: 20px; /* Add space below the heading */
  letter-spacing: 1.5px; /* Slight letter spacing for a clean look */
  font-weight: 400; /* Adjust font weight for balance */
}


.home-h2 {
  font-size: 24px;
  color: #eb4cd5;
  margin-bottom: 10px;
  font-family: "Acme", sans-serif;
  text-align: center;
}

.p-h2 {
  font-family: "Acme", sans-serif;
  font-size: 20px;
  color: #4ccbeb;
  text-align: center;
}

.cards-container {
  display: flex;
  justify-content: center;
  gap: 20px;
  flex-wrap: wrap;
  margin-top: 20px;
  width: 100%;
}

.card {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  padding: 20px;
  width: 250px;
  text-align: center;
  margin: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.card:hover {
  transform: scale(1.05);
}

.card h3 {
  font-size: 25px;
  color: #eb4cd5;
  margin-bottom: 10px;
  font-family: "Acme", sans-serif;
}

.card p {
  font-size: 21px;
  color: #4ccbeb;
  font-family: "Acme", sans-serif;
}

/* New sections added below */
.home-section {
  margin: 20px 0;
  text-align: center;
}

.home-section h2 {
  font-size: 24px;
  color: #eb4cd5;
  margin-bottom: 10px;
  font-family: "Acme", sans-serif;
}

.home-section p {
  font-family: "Acme", sans-serif;
  font-size: 20px;
  color: #4ccbeb;
  text-align: center;
}

.footer {
  background-color: #0A1128;
  color: #ffffff;
  padding: 20px;
  display: flex;
  justify-content: center;
  width: 100%; 
  height: 2rem;
  position: fixed; /* Fixes the footer to the bottom of the page */
  bottom: 0; /* Positions the footer at the bottom */
  left: 0;
  font-family: 'Acme', sans-serif;
}

.footer-p{
  margin: 0;
  font-size: 0.7rem;
}

.footer a {
  color: #4ccbeb;
  text-decoration: none;
}

.footer a:hover {
  text-decoration: underline;
}


/* Responsive media queries */
@media (max-width: 1024px) {
  /* For tablets and medium screens */
  .home-h2 {
    font-size: 22px;
  }

  .p-h2 {
    font-size: 18px;
  }

  .card {
    width: 220px;
  }

  .card h3 {
    font-size: 22px;
  }

  .card p {
    font-size: 18px;
  }

  .home-section h2 {
    font-size: 22px;
  }

  .home-section p {
    font-size: 18px;
  }
}

@media (max-width: 768px) {
  /* For smaller tablets and landscape mobile phones */
  .home-h2 {
    font-size: 20px;
  }

  .p-h2 {
    font-size: 16px;
  }

  .card {
    width: 200px;
  }

  .card h3 {
    font-size: 20px;
  }

  .card p {
    font-size: 16px;
  }

  .cards-container {
    gap: 10px; /* Reduce gap between cards */
  }

  .home-section h2 {
    font-size: 20px;
  }

  .home-section p {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  /* For mobile phones */
  .home-container {
    padding: 10px;
  }

  .home-h2 {
    font-size: 18px;
  }

  .p-h2 {
    font-size: 14px;
  }

  .card {
    width: 100%; /* Make cards full width on mobile */
    padding: 15px;
  }

  .card h3 {
    font-size: 18px;
  }

  .card p {
    font-size: 14px;
  }

  .cards-container {
    flex-direction: column;
    align-items: center; /* Center the cards on mobile */
  }

  .home-section h2 {
    font-size: 18px;
  }

  .home-section p {
    font-size: 14px;
  }
}
