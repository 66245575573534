/* Container for the story page */
.story-page-container {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Align items at the top */
  height: auto;
  padding: 20px;
  margin-left: 300px; /* Sidebar margin for larger screens */
  box-sizing: border-box;
  overflow-y: auto;/* Container for the story page */
  .story-page-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: auto;
    padding: 20px;
    margin-left: 300px; /* Adjust for sidebar */
    box-sizing: border-box;
    overflow-y: auto;
  }
  
  /* Story card styling */
  .story-card {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    max-width: 800px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  
  /* Links container */
  .links-container {
    margin-bottom: 20px;
    margin-left: 200px;
    display: flex;
    justify-content: flex-start;
    gap: 20px;
  }
  
  /* Video and Game Link Styling */
  .video-link, .game-link {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .video-link:hover, .game-link:hover {
    background-color: #0056b3;
  }
  
  /* Story title styling */
  .story-card h2 {
    font-size: 2rem;
    color: #e40abf;
    text-align: center;
    margin-bottom: 20px;
  }
  
  /* Story image styling */
  .story-image {
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  /* Paragraph styling */
  .story-card div {
    font-size: 1.2rem;
    line-height: 1.6;
    color: #333;
    margin-bottom: 15px;
    text-align: justify;
    max-height: 400px; /* Limit max height if you have a lot of text */
    overflow-y: auto; /* Add scrolling for long text */
  }
  
  /* Back to Dashboard Button Styling */
  .back-button {
    position: absolute;
    top: 10px;
    left: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    font-size: 1rem;
    padding: 10px 15px;
    border-radius: 8px;
    cursor: pointer;
  }
  
  .back-button:hover {
    background-color: #0056b3;
  }
  
  /* Responsive styling */
  @media (max-width: 768px) {
    .story-card {
      padding: 15px;
    }
  
    .story-card h2 {
      font-size: 1.5rem;
    }
  
    .story-image {
      max-height: 250px;
    }
  
    .back-button {
      padding: 8px 12px;
      font-size: 0.9rem;
    }
  
    .video-link, .game-link {
      padding: 8px 15px;
      font-size: 0.9rem;
    }
    .p-story p {
      font-size: 1.3rem; /* Slightly smaller font for mobile */
      padding: 8px;
      line-height: 1.6;
    }
  }
  
}

/* Story card styling */
.story-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 800px;
  width: 100%;
  overflow: hidden;
  position: relative;
}

/* Story title styling */
.story-card h2 {
  font-size: 2rem;
  color: #e40abf;
  text-align: center;
  margin-bottom: 20px;
}

/* Story image styling */
.story-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 20px;
}

/* Paragraph styling */
.p-story p {
  font-size: 1.5rem; /* Larger font size for easier reading */
  line-height: 1.8;  /* More space between lines */
  color: #333;
  margin-bottom: 15px; /* Separate paragraphs nicely */
  text-align: justify;
  padding: 10px;
  background-color: #f9f9f9; /* Soft background to make reading comfortable */
  border-radius: 6px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Slight shadow for focus */
}


/* Back to Dashboard Button Styling */
.back-button {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  font-size: 1rem;
  padding: 10px 15px;
  border-radius: 8px;
  cursor: pointer;
}

.back-button:hover {
  background-color: #0056b3;
}

/* --- Responsive Adjustments --- */

/* Medium screens (between 768px to 1024px) */
@media (max-width: 1024px) {
  .story-page-container {
    margin-left: 200px; /* Reduce the margin for medium screens */
    padding: 15px;
  }

  .story-card {
    padding: 15px;
    max-width: 600px; /* Limit max width for smaller devices */
  }

  .story-card h2 {
    font-size: 1.8rem;
  }

  .story-image {
    max-height: 250px;
  }

  .p-story {
    font-size: 1.1rem;
  }

  .back-button {
    padding: 8px 12px;
    font-size: 0.9rem;
  }
}

/* Small screens (less than 768px) */
@media (max-width: 768px) {
  .story-page-container {
    margin-left: 0; /* Remove the margin for small screens */
    padding: 10px;
  }

  .story-card {
    padding: 10px;
    max-width: 100%; /* Make the card take full width */
  }

  .story-card h2 {
    font-size: 1.5rem;
  }

  .story-image {
    max-height: 200px;
  }

  .p-story {
    font-size: 1rem;
  }

  .back-button {
    padding: 6px 10px;
    font-size: 0.85rem;
  }
}

/* Extra small screens (less than 480px) */
@media (max-width: 480px) {
  /* Story page container */
  .story-page-container {
    margin-left: 0;
    padding: 5px; /* Reduce padding to make better use of screen space */
    max-width: 100vw; /* Ensure the container fits within the viewport */
    background-color: #f0f0f0; /* Consistent background color */
  }

  /* Story card styling */
  .story-card {
    padding: 10px; /* Slight increase for better spacing */
    max-width: 100%; /* Ensure the card takes full width */
    border-radius: 6px; /* Slightly reduce border-radius for smaller screens */
  }

  /* Story title */
  .story-card h2 {
    font-size: 1.2rem; /* Smaller title font for better fit */
    text-align: left; /* Align title to the left for a cleaner look */
    margin-bottom: 15px; /* Slightly reduce space below title */
  }

  /* Paragraph (story description) */
  .p-story {
    font-size: 0.9rem; /* Smaller text size for readability on small screens */
    line-height: 1.4; /* Tighter line height for smaller screens */
  }

  /* Back to Dashboard Button */
  .back-button {
    padding: 4px 6px; /* Reduce button size */
    font-size: 0.8rem; /* Smaller font size */
    top: 5px; /* Adjust position to match smaller layout */
    left: 5px; /* Ensure it's close to the edges */
  }

  /* Story image styling */
  .story-image {
    width: 100%;
    max-height: 120px; /* Further reduce the height for smaller screens */
    object-fit: cover; /* Ensure image covers the container well */
    border-radius: 6px; /* Match the card's reduced border-radius */
    margin-bottom: 10px; /* Reduce space below the image */
  }

  /* Links container for video and game links */
  .links-container {
    margin-left: 0; /* Align the links with the content */
    display: flex;
    flex-direction: column; /* Stack links vertically */
    gap: 10px; /* Reduce gap between links */
  }

  /* Video and Game Link Buttons */
  .video-link, .game-link {
    padding: 8px 10px; /* Reduce padding to fit small screens */
    font-size: 0.9rem; /* Adjust font size */
    text-align: center; /* Center text in the buttons */
  }
  .p-story p {
    font-size: 1.1rem; /* Smaller font for very small screens */
    padding: 6px;
    line-height: 1.5;
  }
}

