/* StoryComponent.css */

h1 {
  font-size: 3rem;
  text-align: center;
  color: #ffe600; /* Bright yellow for a kid-friendly vibe */
  text-shadow: 2px 2px #ff00cc;
  margin-bottom: 2rem;
}

input[type="text"], select {
  display: block;
  margin: 1rem auto;
  padding: 1rem;
  width: 80%;
  max-width: 400px;
  font-size: 1.2rem;
  border-radius: 8px;
  border: none;
  outline: none;
  background-color: #f9f9f9;
  color: #333;
  transition: all 0.3s ease-in-out;
}

input[type="text"]:focus, select:focus {
  box-shadow: 0 0 10px rgba(0, 255, 255, 0.5);
  transform: scale(1.05);
}

/* Dropdown (select) specific styles */
select {
  appearance: none; /* Remove default dropdown arrow */
  background: url('data:image/svg+xml;utf8,<svg fill="%230072FF" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>') no-repeat right 10px center;
  background-color: #f9f9f9;
  background-size: 20px;
}

.storycompbutton {
  display: block;
  margin: 2rem auto;
  padding: 1rem 2rem;
  font-size: 1.5rem;
  background-color: #ff00cc; /* Bright pink for fun! */
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.storycompbutton:hover {
  background-color: #ffe600;
  color: #0072ff;
  transform: scale(1.1);
  box-shadow: 0 0 20px rgba(0, 255, 255, 0.6);
}

p {
  font-size: 1.3rem;
  text-align: justify;
  max-width: 600px;
  margin: 2rem auto;
  color: #f0f0f0;
  background: rgba(255, 255, 255, 0.1);
  padding: 1rem;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

p.error {
  color: #ff4d4d;
}
