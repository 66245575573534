/* ThumbnailSlider.css */

/* Container that holds the cards */
.thumbnail-card-container {
  padding: 20px;
  margin-left: 250px; /* Adjust for sidebar space */
  max-width: calc(100% - 250px); /* Ensures content fits next to sidebar */
  box-sizing: border-box;
  overflow: hidden; /* Avoid overflow issues */
}

/* Featured stories title */
.thumbnail-card-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
  color: #e40abf;
}

/* Grid container for the cards */
.thumbnail-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 20px; /* Space between cards */
}

/* Individual card styling */
.thumbnail-card {
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
}

.thumbnail-card:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

/* Image container and image styles */
.thumbnail-image-container {
  width: 100%;
  padding-top: 150%; /* 4:3 aspect ratio */
  position: relative;
}

.thumbnail-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Title below the image */
.thumbnail-title {
  padding: 15px;
  font-size: 1.2rem;
  color: #e40abf;
  text-align: center;
}

/* Responsive design adjustments */
@media (max-width: 1024px) {
  .thumbnail-card-container {
    margin-left: 200px; /* Adjust for smaller sidebar */
    max-width: calc(100% - 200px);
  }
}

@media (max-width: 768px) {
  .thumbnail-card-container {
    margin-left: 150px; /* Adjust further for smaller screens */
    max-width: calc(100% - 150px);
  }
}

@media (max-width: 600px) {
  .thumbnail-card-container {
    margin-left: 0; /* No sidebar on small screens */
    max-width: 100%;
  }

  .thumbnail-title {
    font-size: 1rem; /* Adjust title size for mobile */
  }
}
