/* Sidebar styles */
.sidebar-container {
  width: 250px;
  height: 100vh;
  background-color: purple;
  color: white;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1000; /* Ensure the sidebar is on top of other content */
}

.sidebar-logo {
  width: 50px; /* Adjust the width of your logo as needed */
  margin-bottom: 20px; /* Adds space between the logo and the title */
}

.sidebar-title {
  font-size: 1rem;
  margin-bottom: 20px;
}

.sidebar-links {
  list-style-type: none;
  padding: 0;
}

.sidebar-links li {
  margin-bottom: 15px;
}

.sidebar-links a {
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
}

.sidebar-logout-button {
  background-color: #e40abf;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.sidebar-logout-button:hover {
  background-color: #ff66ff;
}

/* Hamburger menu for small screens */
.hamburger-menu {
  display: none; /* Hidden by default */
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
  cursor: pointer;
  margin: 10px;
  z-index: 1001; /* Higher than the sidebar to remain clickable */
}

.hamburger-menu .bar {
  height: 3px;
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
}

/* Hide the sidebar on smaller screens */
@media (max-width: 768px) {
  .sidebar-container {
    transform: translateX(-100%); /* Hide sidebar by moving it off-screen */
    position: fixed;
    z-index: 1000; /* Ensure it stays on top */
  }

  .sidebar-container.open {
    transform: translateX(0); /* Show the sidebar when menu is open */
    z-index: 1000; /* Keep it on top */
  }

  /* Display the hamburger menu on small screens */
  .hamburger-menu {
    display: flex;
    position: fixed;
    top: 10px;
    left: 10px;
    z-index: 1001; /* Make sure it's clickable */
  }
}
