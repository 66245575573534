/* Styles for the main container when user is logged in */
.app-container.logged-in {
  display: flex;
}


.content-container {
  flex-grow: 1;
  padding: 20px;
}

/* Ensure there's no empty space when not logged in */
.app-container.not-logged-in .content-container {
  margin-left: 0;
  padding: 20px;
}

/* Additional styles as needed */
